import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import Layout from "../components/layout/layout";
import PageHeader from "../components/header/pageHeader";
import aboutStyle from "./styling/about.module.css";

const Privacy = (props) => {
  const { markdownRemark } = props.data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const path = props.location.pathname;
  const intl = useIntl();

  return (
    <Layout
      display="true"
      path={path}
      title={intl.formatMessage({ id: "privacy.title" })}
    >
      <PageHeader header={frontmatter.title} />
      <div className={aboutStyle.privacyWrapper}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <div className="fillContainer" />
      </div>
    </Layout>
  );
};

export default Privacy;

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "/privacy" } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;
